.top_btns[data-v-1b95e832] {
  margin-bottom: 10px;
}
.top_btns .left_search[data-v-1b95e832] {
  float: left;
}
.top_btns .right_btns[data-v-1b95e832] {
  float: right;
  text-align: right;
}
.el-input[data-v-1b95e832] {
  width: 96%;
}
.item-p[data-v-1b95e832] {
  border-top: 1px solid #efefef;
  line-height: 30px;
}
.item-p[data-v-1b95e832]:nth-child(1) {
  border-top: none;
}
[data-v-1b95e832] .el-dropdown-menu__item {
  justify-content: center;
}